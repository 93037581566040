///////////////////////////////////
////////// FONT-FACE //////////////
///////////////////////////////////

//SE FOR GOOGLEFONTES COLOCAR NO APP.BLADE.PHP

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/



@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Variable.woff2') format('woff2'),
      url('../fonts/Satoshi-Variable.woff') format('woff'),
      url('../fonts/Satoshi-Variable.ttf') format('truetype');
      font-weight: 300 900;
      font-display: swap;
      font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-VariableItalic.woff2') format('woff2'),
      url('../fonts/Satoshi-VariableItalic.woff') format('woff'),
      url('../fonts/Satoshi-VariableItalic.ttf') format('truetype');
      font-weight: 300 900;
      font-display: swap;
      font-style: italic;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Light.woff2') format('woff2'),
      url('../fonts/Satoshi-Light.woff') format('woff'),
      url('../fonts/Satoshi-Light.ttf') format('truetype');
      font-weight: 300;
      font-display: swap;
      font-style: normal;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-LightItalic.woff2') format('woff2'),
      url('../fonts/Satoshi-LightItalic.woff') format('woff'),
      url('../fonts/Satoshi-LightItalic.ttf') format('truetype');
      font-weight: 300;
      font-display: swap;
      font-style: italic;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Regular.woff2') format('woff2'),
      url('../fonts/Satoshi-Regular.woff') format('woff'),
      url('../fonts/Satoshi-Regular.ttf') format('truetype');
      font-weight: 400;
      font-display: swap;
      font-style: normal;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Italic.woff2') format('woff2'),
      url('../fonts/Satoshi-Italic.woff') format('woff'),
      url('../fonts/Satoshi-Italic.ttf') format('truetype');
      font-weight: 400;
      font-display: swap;
      font-style: italic;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Medium.woff2') format('woff2'),
      url('../fonts/Satoshi-Medium.woff') format('woff'),
      url('../fonts/Satoshi-Medium.ttf') format('truetype');
      font-weight: 500;
      font-display: swap;
      font-style: normal;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-MediumItalic.woff2') format('woff2'),
      url('../fonts/Satoshi-MediumItalic.woff') format('woff'),
      url('../fonts/Satoshi-MediumItalic.ttf') format('truetype');
      font-weight: 500;
      font-display: swap;
      font-style: italic;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Bold.woff2') format('woff2'),
      url('../fonts/Satoshi-Bold.woff') format('woff'),
      url('../fonts/Satoshi-Bold.ttf') format('truetype');
      font-weight: 700;
      font-display: swap;
      font-style: normal;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-BoldItalic.woff2') format('woff2'),
      url('../fonts/Satoshi-BoldItalic.woff') format('woff'),
      url('../fonts/Satoshi-BoldItalic.ttf') format('truetype');
      font-weight: 700;
      font-display: swap;
      font-style: italic;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-Black.woff2') format('woff2'),
      url('../fonts/Satoshi-Black.woff') format('woff'),
      url('../fonts/Satoshi-Black.ttf') format('truetype');
      font-weight: 900;
      font-display: swap;
      font-style: normal;
}


@font-face {
 font-family: 'Satoshi';
 src: url('../fonts/Satoshi-BlackItalic.woff2') format('woff2'),
      url('../fonts/Satoshi-BlackItalic.woff') format('woff'),
      url('../fonts/Satoshi-BlackItalic.ttf') format('truetype');
      font-weight: 900;
      font-display: swap;
      font-style: italic;
}


$Satoshi: "Satoshi";


$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;


html {
    font-size: 62.5%;
    // 12px = 1.2rem
    // 20px = 2.0rem
    // 35px = 3.5rem
    // 46px = 4.6rem
	// 91px = 9.1rem
    @media (min-width: 700px) and (max-width: 1599px) {
        font-size: 62.5%;
    }
}

@mixin fontTemplate($size, $height, $color, $weight, $family, $align: start) {
    font-size: $size;
    line-height: $height;
    color: $color;
    font-weight: $weight;
    font-family: $family;
	text-align: $align;
}

strong{
    color: #485ce6 !important;
}
