.form-group{
	position: relative;
	margin: 0;
}

input.error, textarea.error, select.error{
	background-color: #fce4e4;
	border: 1px solid #cc0033;
	outline: none;
}

label.error{
	color: red;
	font-size: 12px;
	position: absolute;
	bottom: -20px;
}

.grecaptcha-badge {
    display:none !important;
}

.reveal-text {
    visibility: hidden;
}

.line {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: start;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
